import React, { useEffect, useState } from "react";
import "../Assets/Styles/contactUs.css";
import { Form, Input, Button, Spin, ConfigProvider, notification } from "antd";
import TextArea from "antd/es/input/TextArea";
import { GrLocation } from "react-icons/gr";
import { MdOutlineMail } from "react-icons/md";
import emailjs from "@emailjs/browser";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";

const ContactUs = () => {
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();

  useEffect(() => emailjs.init(process.env.REACT_APP_PUBLIC_KEY), []);

  const onFinish = async (values) => {
    const serviceId = process.env.REACT_APP_SERVICE_ID;
    const templateId = process.env.REACT_APP_TEMPLATE_ID;
    try {
      setLoading(true);
      await emailjs.send(serviceId, templateId, values);
      ReactGA.event({
        category: "User",
        action: "conversion_event_submit_lead_form",
        label: "conversion_event_submit_lead_form",
      });
      api.info({
        message: `Message Recieved`,
        description: "We will get back to you as soon as possible.",
      });
      form.resetFields();
    } catch (error) {
      api.error({
        message: `Error Occurred`,
        description: "Please try again later.",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="contact-us">
      {contextHolder}
      <h1 className="title">Contact Us</h1>
      <h2 className="subtitle">
        Get in Touch with us to Transform your Ideas into IT Solutions.
      </h2>
      <div className="line-sep"></div>

      <div className="page-content">
        <div className="left">
          <div className="contact-box">
            <div className="box">
              <div className="icon-container">
                <GrLocation />
              </div>
              <div className="info">
                <span className="box-title">Address</span>

                <h3>Pakistan Office</h3>
                <span className="box-val">
                  2nd Floor, Flock 599 Q Block, Johar Town, LHE, 54000
                </span>
                <h3>US Office</h3>
                <span className="box-val">
                  7901 4TH ST N STE 21782 ST PETERSBURG, FL 33702
                </span>
              </div>
            </div>
            <div className="box">
              <div className="icon-container">
                <MdOutlineMail />
              </div>
              <div className="info">
                <span className="box-title">Email</span>
                <br />
                <span className="box-val">contact@cousync.com</span>
              </div>
            </div>
          </div>
        </div>
        <div className="right">
          <div className="message-box">
            <h2>Send Message</h2>
            <Form form={form} onFinish={onFinish} layout="vertical">
              <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: "Please enter your name." }]}
              >
                <Input placeholder="Your Name" />
              </Form.Item>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: "Please enter your email." },
                  { type: "email", message: "Please enter a valid email." },
                ]}
              >
                <Input placeholder="Your Email" />
              </Form.Item>
              <Form.Item
                label="Phone"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Please enter your phone number.",
                  },
                ]}
              >
                <Input placeholder="Your Phone Number" />
              </Form.Item>
              <Form.Item
                label="Message"
                name="message"
                rules={[
                  { required: true, message: "Please enter your message." },
                ]}
              >
                <TextArea
                  placeholder="Type your message here. . . . ."
                  rows={4}
                />
              </Form.Item>
              <Form.Item>
                <div className="center">
                  <Button type="primary" htmlType="submit" className="send-btn">
                    Send
                  </Button>
                </div>
              </Form.Item>
            </Form>
            <div className="spin-center">
              {loading ? (
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: "#666edd",
                    },
                  }}
                >
                  <Spin size="large" />
                </ConfigProvider>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
