import React, { useCallback, useEffect, useState } from "react";
import "../Assets/Styles/home.css";
import Profile from "../Assets/Images/profile.png";
import Design from "../Assets/Images/web-design.png";
import Development from "../Assets/Images/coding.png";
import AI from "../Assets/Images/chip.png";
import DevOps from "../Assets/Images/devops.png";
import { Button, Carousel } from "antd";
import { FaQuoteLeft } from "react-icons/fa";
import { FaStar } from "react-icons/fa";
import EndSection from "../Components/endSection";
import { Chrono } from "react-chrono";
import { SiGotomeeting } from "react-icons/si";
import { IoIosPricetag } from "react-icons/io";
import { MdOutlineDesignServices } from "react-icons/md";
import { GrTestDesktop } from "react-icons/gr";
import { TbTruckDelivery } from "react-icons/tb";
import { useNavigate } from "react-router-dom";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const [navbar, setNavbar] = useState(false);
  const handleLinkClick = (e) => {
    navigate(e);
  };

  useEffect(() => {
    const handleScroll = () => {
      const element = document.getElementById("design");
      if (element && element.getBoundingClientRect().top < window.innerHeight) {
        element.classList.add("animate__slideInFromLeft");
      }

      const element2 = document.getElementById("devops");
      if (
        element2 &&
        element2.getBoundingClientRect().top < window.innerHeight
      ) {
        element2.classList.add("animate__slideInFromLeft");
      }

      const element3 = document.getElementById("app");
      if (
        element3 &&
        element3.getBoundingClientRect().top < window.innerHeight
      ) {
        element3.classList.add("animate__slideInFromRight");
      }

      const element4 = document.getElementById("dev");
      if (
        element4 &&
        element4.getBoundingClientRect().top < window.innerHeight
      ) {
        element4.classList.add("animate__slideInFromRight");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const timelineData = [
    {
      text: "We begin by hosting a kickoff meeting to better comprehend your project requirements.",
      category: {
        tag: "Meeting",
        color: "#DB5461",
      },
    },
    {
      text: "We provide you with a quote tailored to fit your budget.",
      category: {
        tag: "Agreement",
        color: "#E7CE47",
      },
    },
    {
      text: "Development of your application to meet your specific requirements.",
      category: {
        tag: "Development",
        color: "#659157",
      },
    },
    {
      text: "We rigorously test your application to ensure it is bug-free.",
      category: {
        tag: "Testing",
        color: "#00C2D1",
      },
    },
    {
      text: "We fulfill our commitment and meet your expectations.",
      category: {
        tag: "Delivery",
        color: "#5D5179",
      },
    },
  ];

  const TimelineItem = ({ data }) => (
    <div className="timeline-item">
      <div className="timeline-item-content">
        <span className="tag" style={{ background: data.category.color }}>
          {data.category.tag}
        </span>
        <time>{data.date}</time>
        <p>{data.text}</p>
        {data.link && (
          <a href={data.link.url} target="_blank" rel="noopener noreferrer">
            {data.link.text}
          </a>
        )}
        <span className="circle" />
      </div>
    </div>
  );

  const Timeline = () => (
    <div className="timeline-container">
      {timelineData.map((data, idx) => (
        <TimelineItem data={data} key={idx} />
      ))}
    </div>
  );

  const items = [
    {
      title: "Introduction",
      timelineContent: (
        <div className="flow-section">
          <SiGotomeeting className="icon" />
          <p>
            We begin by hosting a kickoff meeting to better comprehend your
            project requirements.
          </p>
        </div>
      ),
    },
    {
      title: "Price Agreement",
      timelineContent: (
        <div className="flow-section">
          <IoIosPricetag className="icon" />
          <p>We provide you with a quote tailored to fit your budget.</p>
        </div>
      ),
    },
    {
      title: "Design and Development",
      timelineContent: (
        <div className="flow-section">
          <MdOutlineDesignServices className="icon" />
          <p>
            Development of your application to meet your specific requirements.
          </p>
        </div>
      ),
    },
    {
      title: "Testing",
      timelineContent: (
        <div className="flow-section">
          <GrTestDesktop className="icon" />
          <p>We rigorously test your application to ensure it is bug-free.</p>
        </div>
      ),
    },
    {
      title: "Delivery",
      timelineContent: (
        <div className="flow-section">
          <TbTruckDelivery className="icon" />
          <p>We fulfill our commitment and meet your expectations.</p>
        </div>
      ),
    },
  ];

  return (
    <div className="home">
      <div className="title-section">
        <div className="title">
          <h1>WE CRAFT DIGITAL SOLUTIONS</h1>
        </div>
        <div className="text">
          <p>
            Step into the cutting-edge realm of tailored software solutions,
            where innovation meets customization. Your journey to unparalleled
            software experiences starts here!
          </p>
        </div>
      </div>
      {/* <Button className="btn-touch">Learn More</Button> */}
      <div className="spacer"></div>
      <div className="section-header">
        <h1>Unveiling the Magic: Our Work Process</h1>
      </div>
      <div>
        <div className="breakdown">
          <Timeline />
        </div>
      </div>
      {/* <div className="spacer"></div> */}
      <div className="section-header">
        <h1>Services</h1>
      </div>
      <div className="main-tech-section">
        <div
          className="technologies-section"
          onClick={() => handleLinkClick("/services")}
        >
          <div className="item" id="design">
            <div className="icon-cont">
              <img src={Design} alt="Design" />
            </div>
            <div>
              <h3>Design</h3>
            </div>
            <div>
              <p>
                Ignite code aesthetics with our design wizardry. We architect
                seamless user journeys, crafting UI/UX symphonies that redefine
                digital elegance. Your code deserves an extraordinary canvas.
              </p>
            </div>
          </div>
          <div className="item" id="dev">
            <div className="icon-cont">
              <img src={Development} alt="Application" />
            </div>
            <div>
              <h3>Development</h3>
            </div>
            <div>
              <p>
                Forge your own digital destiny with our custom software
                solutions. We don't just code; we architect tailored
                innovations, where every line reflects your unique vision.
                Elevate your tech swagger with applications as distinctive as
                your ambition.
              </p>
            </div>
          </div>

          <div className="item" id="devops">
            <div className="icon-cont">
              <img src={DevOps} alt="DevOps" />
            </div>
            <div>
              <h3>DevOps</h3>
            </div>
            <div>
              <p>
                Elevate your tech with our DevOps mastery. We orchestrate
                seamless AWS symphonies and wield automation magic, ensuring
                your code glides through the clouds of innovation with celestial
                efficiency.
              </p>
            </div>
          </div>
          <div className="item" id="app">
            <div className="icon-cont">
              <img src={AI} alt="Application" />
            </div>
            <div>
              <h3>AI / ML</h3>
            </div>
            <div>
              <p>
                Forge your own digital destiny with our custom software
                solutions. We don't just code; we architect tailored
                innovations, where every line reflects your unique vision.
                Elevate your tech swagger with applications as distinctive as
                your ambition.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="spacer"></div>

      <div className="services">
        <h1>
          We provide a diverse range of technology stacks tailored to suit your
          specific needs.
        </h1>
        <p>
          Discover our diverse technology stacks tailored to your unique needs.
          Whether it's scalability, performance, or specific development
          preferences, our range ensures a customized solution for every
          project.
        </p>

        <Button
          className="btn-touch"
          onClick={() => handleLinkClick("/technologies")}
        >
          Learn More
        </Button>
      </div>

      <div className="spacer"></div>

      <div className="section-header">
        <h1>What Our Clients Say</h1>
      </div>
      <div>
        <div className="testimonials-section">
          <Carousel autoplay>
            <div className="item testimonial-card">
              <main className="test-card-body">
                <div className="quote">
                  <FaQuoteLeft />
                </div>
                <p>
                  This might have been the best experience I've had since I
                  started with Fiverr. Extremely professional and experienced.
                  These guys are a great resource, solid and reliable for my
                  Django project. I immediately, reengaged them for the next
                  sprint.
                </p>
                <div className="profile">
                  <div className="profile-image">
                    <img src={Profile} alt="Profile" />
                  </div>
                  <div className="profile-desc">
                    <span>Bob Arnold</span>
                    <div className="ratings">
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                    </div>
                  </div>
                </div>
              </main>
            </div>
            <div className="item testimonial-card">
              <main className="test-card-body">
                <div className="quote">
                  <FaQuoteLeft />
                </div>
                <p>
                  Abdul and his team have been exceptionally professional and
                  are always there to help you. They respond perfectly to what
                  you ask them and they are attentive to detail and sometimes
                  have their own expertise, which in my case was very useful. I
                  will of course continue to work with them in the future,
                  because I am extremely satisfied with their work.
                </p>
                <div className="profile">
                  <div className="profile-image">
                    <img src={Profile} alt="Profile" />
                  </div>
                  <div className="profile-desc">
                    <span>Camille Sanch</span>
                    <div className="ratings">
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                    </div>
                  </div>
                </div>
              </main>
            </div>
            <div className="item testimonial-card">
              <main className="test-card-body">
                <div className="quote">
                  <FaQuoteLeft />
                </div>
                <p>
                  Working with Abdul and his team has been a phenomenal
                  experience. Their professionalism, attention to detail, and
                  ability to exceed expectations have been impressive! HIGHLY
                  recommend them for anyone looking for a top-notch professional
                  website for their business 😊.
                </p>
                <div className="profile">
                  <div className="profile-image">
                    <img src={Profile} alt="Profile" />
                  </div>
                  <div className="profile-desc">
                    <span>Yan Kiu</span>
                    <div className="ratings">
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                    </div>
                  </div>
                </div>
              </main>
            </div>
            <div className="item testimonial-card">
              <main className="test-card-body">
                <div className="quote">
                  <FaQuoteLeft />
                </div>
                <p>
                  Excellent work! The team maintained open communication
                  throughout the project, remaining flexible to changes, and
                  effectively delivering results. I am impressed with their
                  dedication and professionalism. I look forward to
                  collaborating with them again in the future.
                </p>
                <div className="profile">
                  <div className="profile-image">
                    <img src={Profile} alt="Profile" />
                  </div>
                  <div className="profile-desc">
                    <span>Nikhil</span>
                    <div className="ratings">
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                    </div>
                  </div>
                </div>
              </main>
            </div>
            <div className="item testimonial-card">
              <main className="test-card-body">
                <div className="quote">
                  <FaQuoteLeft />
                </div>
                <p>
                  Devised an effective solution to accomplish what I needed.
                </p>
                <div className="profile">
                  <div className="profile-image">
                    <img src={Profile} alt="Profile" />
                  </div>
                  <div className="profile-desc">
                    <span>Lamar</span>
                    <div className="ratings">
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                    </div>
                  </div>
                </div>
              </main>
            </div>
            <div className="item testimonial-card">
              <main className="test-card-body">
                <div className="quote">
                  <FaQuoteLeft />
                </div>
                <p>
                  The experience was truly exceptional. Every aspect exceeded my
                  expectations, from the service to the quality of the product
                  or service provided. I was thoroughly impressed and would
                  highly recommend it to others.
                </p>
                <div className="profile">
                  <div className="profile-image">
                    <img src={Profile} alt="Profile" />
                  </div>
                  <div className="profile-desc">
                    <span>Met</span>
                    <div className="ratings">
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                    </div>
                  </div>
                </div>
              </main>
            </div>
            <div className="item testimonial-card">
              <main className="test-card-body">
                <div className="quote">
                  <FaQuoteLeft />
                </div>
                <p>
                  The team demonstrated excellent communication, consistently
                  providing timely updates. They crafted a superb, bug-free
                  application. I highly recommend working with them and eagerly
                  anticipate our next collaboration.
                </p>
                <div className="profile">
                  <div className="profile-image">
                    <img src={Profile} alt="Profile" />
                  </div>
                  <div className="profile-desc">
                    <span>Benjamin Oliver</span>
                    <div className="ratings">
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                    </div>
                  </div>
                </div>
              </main>
            </div>
          </Carousel>
        </div>
      </div>
      <EndSection />
    </div>
  );
};

export default Home;
